
.containerLoading {
    /*background: url(../../../image/loading.gif) no-repeat center;*/
    width: 200px;
    height: 200px;
    align-items: center;
    justify-content: center;
}

@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.lbl_header_name_portion {
    padding-left: 20px;
    padding-bottom: 7px;
}
.lbl_header_naming {
    margin: 10px 0;
    font-family: OpenSans;
    font-weight: bold;
    font-size: 30px;
    font-weight: bolder;
}

.lbl_input_font {
    font-size: 16px;
    font-weight: normal;
}

.lbl_detail_naming {
    font-size: 18px;
}

.switch_div
{
    display:flex;
    align-items: center;
}


.lbl_switch
{
    white-space: nowrap;
    font-size: 18px;
    margin-bottom: 0;
    margin-right: 1rem;
}


/*react datepicker*/
/*React overwrite*/
.react-datepicker__input-container,
.react-datepicker-wrapper {
    display: block !important;
}

.react-datepicker__current-month,
.calendarStyle {
    font-size: 14px !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    width: 24px !important;
    line-height: 24px !important;
}

/* .react-datepicker__time-list {
    padding-left: 0px !important;
    padding-right: 0px !important;
} */

.react-datepicker__time-list-item {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.react-datepicker__current-month, .react-datepicker-time__header {
    font-size: 14px !important;
}

.channel_rate_tbl_outer {
    border: 1px solid
}

#timeOnly > div > div > div > div.react-datepicker__month-container {
    display: none !important;
}
#last-Select>div>div.css-kj6f9i-menu{
    position:relative !important;
 }

.input_controls_required{
    color:red;
    margin-bottom: 0;
}


.lbl_select_validation {
    display: none;
}

.form-control::-webkit-input-placeholder {
    color: #CDCED2;
  }
  .form-control:-moz-placeholder {
    color: #CDCED2;
  }
  .form-control::-moz-placeholder {
    color: #CDCED2;
  }
  .form-control::placeholder {
    color: #CDCED2;
  }
  .form-control:-ms-input-placeholder {
    color: #CDCED2;
  }
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Provide sufficient contrast against white background */

code {
  color: #E01A76;
}

.row {
  margin-top: 15px;
}

.logoContainer {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 35px;
  width: 96px
}

.div-primary {
  margin-Top: 10px;
}

.div-otp {
  margin-Top: 30px;
}

.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: 65vh;
}

.label-small-300 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #333333;
}

.label-normal-normal {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  /*400*/
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #333333;
}

.label-normal {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #333333;
}

.label-small-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  /*700*/
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #333333;
}

.label-prompt-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  /* RK text */
  color: #333333;
  padding: 5px;
}

.label-title {
  /* Title 4 Semibold */
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.01em;
  /* RK text */
  color: #333333;
  padding: 20px;
}

.label-otp {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  /*400*/
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #333333;
}

.btn-primary {
  color: black;
  background-color: #FFF;
  border: 1px solid #CDCED2;
  box-sizing: border-box;
  border-radius: 8px;
}

.btn-primary:hover {
  color: black;
  background-color: #f0f5f7;
}

.btn-primary:active {
  color: black;
  background-color: #f0f5f7;
}

.btn-primary-border {
  color: black;
  background-color: #FFF;
  border: 1px solid #56CCF2;
  border-radius: 10px;
}

.btn-primary-border:hover {
  color: black;
  background-color: #f0f5f7;
}

.btn-primary-border:active {
  color: black;
  background-color: transparent;
}

.btn-menu {
  color: #45C2FF;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  background-color: white;
  border: 1px solid #56CCF2;
  box-sizing: border-box;
  border-radius: 24px;
  padding: 12px;
  margin: 12px;
  width: inherit
}

.btn-menu:hover {
  color: white;
  background-color: #45C2FF;
  border: 1px solid #56CCF2;
}

.btn-sidemenu {
  color: #56CCF2;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  /* identical to box height */
  letter-spacing: 0.01em;
  background-color: white;
  border: 1px solid white;
  padding: 10px 20px 10px;
}

.btn-sidemenu:hover {
  color: white;
  background-color: #45C2FF;
  border: 1px solid white;
}

.btn-cancel {
  color: #45C2FF;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  background-color: white;
  border: 1px solid #FFF;
  box-sizing: border-box;
  border-radius: 24px;
  width: 100%;
  padding: 13px
}

.btn-create {
  color: #FFF;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  background-color: #FF8943;
  border: 1px solid #FF8943;
  box-sizing: border-box;
  border-radius: 24px;
  width: 100%;
  padding: 13px
}

.btn-create:disabled {
  color: white;
  background: #CDCED2;
  border: 1px solid #CDCED2;
  box-sizing: border-box;
  border-radius: 24px;
}

.btn-modal {
  color: white;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  background-color: #45C2FF;
  border: 1px solid #56CCF2;
  box-sizing: border-box;
  border-radius: 24px;
  padding: 13px;
  /*width: 40%*/
}

.btn-modal:disabled {
  color: white;
  background: #CDCED2;
  border: 1px solid #CDCED2;
  box-sizing: border-box;
  border-radius: 24px;
}

.btn-modal-cancel {
  color: #45C2FF;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  background-color: white;
  border: 1px solid #FFF;
  box-sizing: border-box;
  border-radius: 24px;
  padding: 13px;
  /*width: 40%;*/
}

.btn-modal-cancel:disabled {
  color: white;
  background: #CDCED2;
  border: 1px solid #CDCED2;
  box-sizing: border-box;
  border-radius: 24px;
}

.btn-cancel-reservation {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #EF4B48;
  border: 0px;
}

.btn-cancel-reservation[disabled=disabled], .btn-cancel-reservation:disabled {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #CDCED2;
  border: 1px;
}

.btn-footer {
  color: white;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  background-color: #45C2FF;
  border: 1px solid #56CCF2;
  box-sizing: border-box;
  border-radius: 24px;
  padding: 13px;
}

.btn-footer:disabled {
  color: white;
  background: #CDCED2;
  border: 1px solid #CDCED2;
  box-sizing: border-box;
  border-radius: 24px;
}

/*show list button with margin bottom*/

.btn-list {
  width: 100%;
  text-align: left;
  padding: 15px;
  line-height: 2px;
  margin-bottom: 20px
}

.btn-minus {
  background-image: url('./image/minus_icon.svg');
  height: 24px;
  width: 24px;
  margin: 5px;
  border: 0px;
}

.btn-plus {
  background-image: url('./image/plus_icon.svg');
  height: 24px;
  width: 24px;
  margin: 5px;
  border: 0px;
}

.filterInput {
  padding: 20px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.searchSection {
  align-items: center;
  border: 1px solid #CDCED2;
  box-sizing: border-box;
  border-radius: 8px;
}

.footer {
  position: absolute;
  bottom: 0;
}

.otpContainer {
  margin: 5px 5px;
  text-align: center;
}

.otpInput {
  width: 11%;
  height: 3rem;
  margin: 0px 15px 0px 0px;
  font-size: 1rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.otpLastInput {
  width: 11%;
  height: 3rem;
  margin: 0px;
  font-size: 1rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.btn-otp-submit {
  color: white;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  letter-spacing: 0.01em;
  background: linear-gradient(270deg, #0DB0A7 0%, #24437A 100%);
  border: 1px solid #0DB0A7;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px;
}

.btn-otp-resend {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  border: 0px;
}

.btn-cross {
  background-image: url('./image/cross_menu_icon.svg');
  height: 15px;
  width: 15px;
  border: 1px solid #FFF;
  box-sizing: border-box;
  border-radius: 24px;
  padding: 13px;
  position: absolute;
}